.main {
    display: flex;
    flex-direction: column;
  }
  
  .btn-orange {
    background-color: rgb(238, 106, 30) !important;
    border-color: rgb(218, 127, 42) !important;
    color: white;
  }
  
  .btn-orange:hover,
  .btn-orange:focus,
  .btn-orange:active,
  .btn-orange:focus:active {
    background-color: rgb(207, 92, 25) !important;
    border-color: rgb(218, 127, 42) !important;
    color: white;
    box-shadow: none;
    outline: none;
  }
  
  .candidate-box {
    position: absolute;
    top: -10px;
    right: 10px;
    border: 1px solid #ddd;
    padding: 5px;
    background-color: white;
    border-radius: 10px;
  }
  
  .content-box {
    border: 1px solid #0000003a;
    padding: 20px;
    margin: 10px;
    background-color: white;
    flex: 1;
    border-radius: 10px;
    height: 60vh;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  
  .footer-buttons {
    position: fixed;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 20px;
  }
  