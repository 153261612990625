.div-table {
  display: table;
  font-size: 12px;
  width: 100%;
  background-color: #fff;
  border-spacing: 5px;
  /* cellspacing:poor IE support for  this */
}

.div-table-row {
  /* display: table-row;*/
  width: 200%;
  clear: both;
}

.div-table-col {
  float: left;
  /* fix for  buggy browsers */
  display: table-column;
  width: 7.6%;
  background-color: #fff;
}

.plus {
  cursor: pointer;
  display: table-cell !important;
}

.minus {
  cursor: pointer;
  display: none !important;

}

.DraftEditor-editorContainer {
  z-index: 0;
}

.rdw-editor-main {
  padding: 10px;
  height: auto; /* Set the desired height */
  overflow: auto;
  border: 1px solid #ccc;
}

.wrapperClassName {
  border: 1px solid #D5DADF;

}

.toolbarClassName {
  border-bottom-style: outset;
}


.loader {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: absolute;
  top: 46%;
  left: 81%;
  background: #cccccc;
  box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
  z-index: 99999;
}

@keyframes shadowPulse {
  20% {
    background: #FF3D00;
    box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
  }

  40% {
    background: #cccccc;
    box-shadow: -24px 0 #FF3D00, 18px 0 #cccccc;
  }

  60% {
    background: #FF3D00;
    box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
  }

  80% {
    background: #cccccc;
    box-shadow: -24px 0 #FF3D00, 18px 0 #cccccc;
  }

  100% {
    background: #ee4e1d;
    box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
  }

}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(70%) sepia(70%) saturate(500%) hue-rotate(330deg);
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  box-shadow: inset 0 0 0 9999px #fef4ea !important;
  left: 0px;

}

.second-col {
  left: 3.4%;
  box-shadow: inset 0 0 0 9999px #fef4ea !important;
}

.bdr {
  border-right: 1px solid #ee5121;
}

.css-b62m3t-container {}

.mySelect__value-container {
  max-height: 120px !important;
}

.topleft {
  vertical-align: inherit;
}

.card::-webkit-scrollbar {
  width: 0.5em;
}

.card::-webkit-scrollbar-thumb {
  background-color: #FF5C35;
  border-radius: 0.25em;
}

.css-t3ipsp-control{
  border-color: #FF5C35 !important;
  box-shadow: 0 0 0 1px #FF5C35 !important;
}
.css-t3ipsp-control :focus{
  border-color: #FF5C35 !important;
}